.hero {
  position: relative;
  padding-top: 30px;
  margin-bottom: 70px;
  background-size: 500px;
  background: linear-gradient(0, #309B19, #39A730 200px, #D7F1FF 100%);

  &:before {
    content: '';
    position: absolute;
    bottom: -70px;
    left: 0;
    background: center bottom / cover url("../img/hero-bg-bottom.png") no-repeat;
    width: 100%;
    height: 100px;
    z-index: -1;
  }
}

.hero__container {
  max-width: 960px;
}

.hero__logo-wrap {
  text-align: center;
}

.hero h1 {
  text-align: center;
  font-family: var(--secondary-font);
}


@media (width > 768px) {
  .hero {
    background: url("../img/bg-greendoors-desktop.jpg") no-repeat center center;
    background-size: cover;
    &:before {
      background-image: url('../img/hero-bg-bottom-desktop.png');
    }
  }
}

html {
  color: var(--color-dark);
  font-family: var(--primary-font);
  font-size: var(--font-size-body);
  line-height: 1.4;
  box-sizing: border-box;
}

body {
  font-family: var(--primary-font);
  font-size: var(--font-size-body);
}

html, body {
  height: 100%;
}

::-moz-selection {
  background-color: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden,
[hidden] {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

/*
 * Extends the .sr-only class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

.invisible {
  visibility: hidden;
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

.app {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  min-height: 100%;
}

.container {
  width: min(90%, 1200px);
  margin: 0 auto;
}

.color-font-primary {
  color: var(--color-light);
}

.main {
  display: grid;
  grid-gap: 40px;
}

.title {
  margin-bottom: 40px;
  text-align: center;
  font-family: var(--secondary-font);
  font-size: var(--font-size-title);

}

.about {
  text-align: center;
  font-family: var(--secondary-font);
  padding: clamp(30px, 3vw, 75px) 0;
  color: var(--color-light);
  background-color: var(--bg-primary);
}

.about .container {
  max-width: 960px;
}

.about p {
  margin-top: 0;
  margin-bottom: 0;
  font-family: var(--secondary-font);
  font-size: clamp(15px, 2vw, 22px);
  line-height: 1.5;
}

.swiper {
  display: none!important;
}

.light-gallery {
  display: grid;
  grid-gap: 5px;
  grid-template-areas:
        "a b"
        "c d"
        "e f"
        "g o";
}

@media (width > 800px) {
  .light-gallery {
    grid-template-areas:
        "a a b"
        "c d e"
        "f g o";
  }
}

.light-gallery a {
  &:nth-child(1) {
    grid-area: a;
  }
    &:nth-child(2) {
        grid-area: b;
    }
    &:nth-child(3) {
        grid-area: c;
    }
    &:nth-child(4) {
        grid-area: d;
    }
    &:nth-child(5) {
        grid-area: e;
    }
    &:nth-child(6) {
        grid-area:f;
    }
    &:nth-child(7) {
        grid-area: g;
    }
    &:nth-child(8) {
        grid-area: o;
    }
}

.light-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cookie-banner {
  position : fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  background: linear-gradient(0, #9bdc8c, #62af5b 200px, #D7F1FF 100%);
  z-index: 10;
}

.cookie-banner p {
  color: var(--color-dark);
}

.cookie-banner button {
  border-radius: 5px;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  z-index: 2;
}

.header__menu {
  display: none
}


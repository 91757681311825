@import "normalize.css";
/*@import "../../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";*/
/*@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";*/
/*@import "../../node_modules/lightgallery/css/lightgallery-bundle.min.css";
@import "../../node_modules/lightgallery/css/lg-transitions.css";
@import "../../node_modules/lightgallery/css/lg-medium-zoom.css";
@import "../../node_modules/lightgallery/css/lg-fullscreen.css";*/
@import url("./vars.css");
@import url("./fonts.css");
@import url("./header.css");
@import url("./main.css");
@import url("./hero.css");
@import url("./service-list.css");
@import url("./service-info.css");
@import url("./lesson-info.css");
@import url("./contacts.css");
@import url("./footer.css");
